<template>
  <component :is="taskData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="taskData === undefined">
      <h4 class="alert-heading">{{ $t("message.errorLoadingData") }}</h4>
      <div class="alert-body">
        {{ $t("message.errorTask") }}
        <b-link class="alert-link" :to="{ name: 'apps-team-list' }">
          {{ $t("message.listOfTask") }}
        </b-link>
        {{ $t("message.forOtherTask") }}
      </div>
    </b-alert>

    <template v-if="taskData">
      <b-card>
        <h2 class="mb-2">{{ $t("message.tasks.taskEdit") }}</h2>

        <b-overlay
          variant="transparent"
          opacity="1.00"
          blur="6px"
          :show="showOverlay"
          rounded="sm"
          no-wrap
        ></b-overlay>

        <b-row>
          <b-col md="8">
            <b-form-group label-for="nameTasks">
              <template v-slot:label>
                {{ $t("message.tableHeader.name") }}
              </template>
              <b-form-input
                v-model="taskData.subject"
                label="name"
                label-for="nameTasks"
              />
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group label="Ínicio " label-for="createdDate">
              <template v-slot:label>
                {{ $t("message.date.start") }}
              </template>
              <flat-pickr
                id="created-date"
                v-model="taskData.startDate"
                class="form-control"
                :config="flatConfig"
              />
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group label="Fim" label-for="endDate">
              <template v-slot:label>
                {{ $t("message.date.end") }}
              </template>
              <flat-pickr
                id="end-date"
                v-model="taskData.endDate"
                class="form-control"
                :config="flatConfig"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group label="Descrição" label-for="descriptionTasks">
              <template v-slot:label>
                {{ $t("message.projects.description") }}
              </template>
              <quill-editor
                id="title"
                style="margin-bottom: 52px; height: 200px"
                :options="editorOption"
                v-model="taskData.description"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="2">
            <b-form-group label-for="deadlineTasks">
              <template v-slot:label>
                {{ $t("message.projects.estimatedTime") }}
              </template>
              <b-form-input
                input-id="estimatedTime"
                id="number"
                type="number"
                label="estimatedTime"
                label-for="deadlineTasks"
                v-model="taskData.estimateTime"
              />
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group label-for="projectId">
              <template v-slot:label>
                {{ $t("message.tasks.priority") }}
              </template>
              <v-select
                v-model="taskData.priority"
                :options="taskPriorityOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="task-status"
              />
            </b-form-group>
          </b-col>

          <b-col md="8">
            <b-form-group label-for="userId">
              <template v-slot:label>
                {{ $t("message.team.members") }}
              </template>
              <v-select
                multiple
                v-model="taskData.members"
                :options="userOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="task-users"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="projectId">
              <template v-slot:label>
                {{ $t("message.projects.project") }}
                <span
                  class="cursor-pointer"
                  @click="openProjectDetails(taskData.projectId)"
                >
                  <IconLinkInternal size="12"/>
                </span>
                <!-- <feather-icon
                  icon="ExternalLinkIcon"
                  size="14"
                  class="cursor-pointer"
                  @click="openProjectDetails(taskData.projectId)"
                /> -->
              </template>
              <v-select
                :clearable="false"
                v-model="projectName"
                input-id="task-projects"
                disabled
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label-for="projectId">
              <template v-slot:label>
                {{ $t("message.tasks.type") }}
              </template>
              <v-select
                v-model="taskData.type"
                :options="taskTypeOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="task-status"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <b-form-group label-for="projectId">
              <template v-slot:label>
                {{ $t("Progress") }} {{ taskData.percentage }}%
              </template>
              <input
                class="slider"
                v-model="taskData.percentage"
                type="range"
                min="0"
                max="100"
                value="taskData.percentage"
                id="done"
                step="1"
                v-bind:style="gradientColorProgressBar()"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Spacer -->
        <hr class="invoice-spacing" />

        <b-form-group label-for="wildcard">
          <template v-slot:label> {{ $t("uploadFile") }} </template>
          <b-form-file
            style="width: 350px"
            id="wildcard"
            ref="file-input"
            class="mr-1"
            v-model="file"
            browse-text="Procurar"
            placeholder="Nenhum arquivo selecionado"
            drop-placeholder="Arraste arquivo aqui"
            :accept="fileFormats"
          >
          </b-form-file>
          <b-button
            style="margin-top: 7px"
            variant="warning"
            class="btn-icon mr-1"
            @click="file = null"
            v-if="file"
          >
            {{ $t("clean") }}  <feather-icon icon="RefreshCwIcon" />
          </b-button>
          <b-button
            style="margin-top: 7px"
            variant="success"
            class="btn-icon"
            @click="addFile()"
            v-if="file"
          >
            {{ $t("createFile") }}  <feather-icon icon="SendIcon" />
          </b-button>
        </b-form-group>

        <br />

        <b-row>
          <b-col
            style="max-width: 200px; !important"
            v-for="item in filesData"
            :key="item.id"
          >
            <b-card
              text-variant="white"
              class="text-center"
              bg-variant="primary"
            >
              <feather-icon size="40" icon="FileIcon" />

              <div class="truncate">
                <br />
                <span>{{ item.name }}</span>
              </div>
              <br />
              <b-row>
                <b-col md="6"
                  ><b-button
                    size="sm"
                    @click="downloadFile(item.id, item.name)"
                    v-if="restrictions('task_file_download')"
                    ><feather-icon size="20" icon="DownloadCloudIcon"
                  /></b-button>
                </b-col>

                <b-col md="6"
                  ><b-button
                    size="sm"
                    v-if="restrictions('task_file_delete')"
                    v-b-modal.modal-delete-file-confirmation
                    @click="
                      selectedFileId = item.id;
                      selectedFileName = item.name;
                    "
                    ><feather-icon size="20" icon="XIcon"
                  /></b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-modal
          id="modal-delete-file-confirmation"
          modal-class="modal-danger"
          centered
          title="Deletar"
          hide-footer
        >
          <b-card-text>
            Você tem certeza que deseja deletar o arquivo
            <b>{{ selectedFileName }}</b> ?</b-card-text
          >
          <div class="d-grid gap-2 d-md-flex justify-content-md-end">
            <b-button
              v-b-modal.modal-danger
              class="mt-2"
              variant="danger"
              @click="deleteFile()"
            >
              Deletar
            </b-button>
          </div>
        </b-modal>
      </b-card>
    </template>
    <div class="text-right">
      <b-button
        v-b-modal.modal-delete-confirmation
        variant="outline-danger"
        v-if="restrictions('button_delete_task')"
      >
        {{ $t("message.buttons.delete") }}
      </b-button>
      <b-button
        class="ml-1"
        variant="primary"
        @click="updateTask(), $router.go(-1)"
      >
        {{ $t("message.buttons.save") }}
      </b-button>
    </div>

    <b-modal
      id="modal-delete-confirmation"
      modal-class="modal-danger"
      centered
      :title="$t('delete')"
      hide-footer
    >
      <b-card-text>
        {{ $t("message.deletAcount") }}
      </b-card-text>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <b-button
          v-b-modal.modal-danger
          class="mt-2"
          variant="danger"
          :to="{ name: 'apps-task-list' }"
          @click="deleteTask()"
        >
          {{ $t("message.buttons.delete") }}
        </b-button>
      </div>
    </b-modal>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
  BFormTextarea,
  BCardText,
  BFormSpinbutton,
  BFormInvalidFeedback,
  BProgress,
  BProgressBar,
  BCardBody,
  BFormFile,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import vSelect from "vue-select";
import router from "@/router";
import flatPickr from "vue-flatpickr-component";
import date from "@/@core/utils/date";
import dateInputEdit from "@/@core/utils/dateInputEdit";
import formValidation from "@core/comp-functions/forms/form-validation";
import Email from "@/@core/utils/email/email.js";
import store from "@/store";
import AccessControl from "@core/utils/access-control";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import getHeaderFile from "@core/utils/authFile";
import download from "downloadjs";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import IconLinkInternal from "@core/components/iconLink/IconLinkInternal.vue"

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    vSelect,
    BFormTextarea,
    BCardText,
    flatPickr,
    BFormSpinbutton,
    BProgress,
    BProgressBar,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    BCardBody,
    BFormFile,
    BOverlay,
    IconLinkInternal
  },

  data() {
    return {
      //Select

      showOverlay: false,
      userOptions: [],
      taskStatusOptions: [],
      taskTypeOptions: [],
      taskPriorityOptions: [],

      flatConfig: { altFormat: "d/m/Y", altInput: true, format: "d/m/Y" },
      userData: store.state.user.userData,

      taskOldMembers: [],

      projectName: "",

      paramData: store.state.user.paramData,

      fileFormats: "",
      fileMaxSize: 0,
      file: null,
      filesData: [],
      selectedFileId: "",
      selectedFileName: "",

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.flatConfig = dateInputEdit(newVal);
    },
    file: function (newVal, oldVal) {
      if (newVal != null) {
        const maxAllowedSize = this.fileMaxSize * 1024 * 1024;
        if (newVal.size > maxAllowedSize) {
          // Here you can ask your users to load correct file
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Não permitido",
              icon: "XIcon",
              variant: "danger",
              text: "Arquivo com tamanho maior do que permitido pelo sistema",
            },
          });
          this.$refs["file-input"].reset();
        }
      }
    },
  },

  mounted() {
    if (this.$i18n.locale) {
      this.flatConfig = dateInputEdit(this.$i18n.locale);
    }
  },

  async created() {
    this.showOverlay = true;
    var optionsList = [];
    await axios
      .get(`${URL_API}task_status`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].status;
          optionsList.push(element);
          this.taskStatusOptions.push({
            label: optionsList[i],
            value: optionsList[i],
          });
        }
      });

    var typeList = [];
    await axios
      .get(`${URL_API}task_type`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].type;
          typeList.push(element);
          this.taskTypeOptions.push({ label: typeList[i], value: typeList[i] });
        }
      });

    var priorityList = [];
    await axios
      .get(`${URL_API}task_priority`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].priority;
          priorityList.push(element);
          this.taskPriorityOptions.push({
            label: priorityList[i],
            value: priorityList[i],
          });
        }
      });

    await this.getTaskFilesData();
    this.loadFileFormats();

    this.taskOldMembers = this.taskData.members;

    this.projectMembersList(this.taskData.projectId);
    
    setTimeout(() => {
      this.showOverlay = false;
    }, 1000)
  },

  methods: {
    async getTaskFilesData() {
      this.files = [];
      await axios({
        method: "get",
        url: `${URL_API}file/${router.currentRoute.params.id}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        this.filesData = response.data;
      });
    },

    loadFileFormats() {
      this.fileFormats = this.paramData.fileTypes.join(", ").toString();
      this.fileMaxSize = this.paramData.maxFileSize;
    },

    addFile() {
      let formData = new FormData();
      formData.append("file", this.file);

      axios
        .post(
          `${URL_API}file/task/${this.userData.id}/${router.currentRoute.params.id}`,
          formData,
          {
            headers: getHeaderFile(this.userData),
          }
        )
        .then((response) => {
          if (!response.data.id && response.data != "") {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("noDataAvailable"),
                icon: "XIcon",
                variant: "danger",
                text: this.$i18n.t("noDataAvailableDesc"),
              },
            });
          } else if (response.data === "") {
            this.getTaskFilesData();
            this.file = null;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("Error"),
                icon: "XIcon",
                variant: "warning",
                text: this.$i18n.t("offline"),
              },
            });
          } else {
            this.getTaskFilesData();
            this.file = null;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("message.toast.create"),
                icon: "CheckIcon",
                variant: "success",
                text: this.$i18n.t("message.toast.create"),
              },
            });
          }
        });
    },

    deleteFile() {
      axios({
        method: "delete",
        url: `${URL_API}file/${this.selectedFileId}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        if (response.data === "") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("Error"),
              icon: "XIcon",
              variant: "warning",
              text: this.$i18n.t("offline"),
            },
          });
        } else {
          this.getTaskFilesData();
          this.$root.$emit("bv::hide::modal", "modal-delete-file-confirmation");
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Deletado com sucesso!",
              icon: "CheckIcon",
              variant: "success",
              text: "Arquivo deletado com sucesso",
            },
          });
        }
      });
    },

    downloadFile(id, name) {
      axios({
        method: "get",
        url: `${URL_API}file/download/${id}`,
        headers: getHeader(this.userData),
        responseType: "blob",
      }).then((response) => {
        if (response.data.size === 0) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("Error"),
              icon: "XIcon",
              variant: "warning",
              text: this.$i18n.t("offline"),
            },
          });
        } else {
          const content = response.headers["content-type"];
          download(response.data, name, content);
        }
      });
    },

    async projectMembersList(projectId) {
      var projectObj = [];
      await axios({
        method: "get",
        url: `${URL_API}project/${projectId}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        projectObj = response.data;
      });

      this.projectName = projectObj.name;

      var teamObj = [];
      for (var i = 0; i < projectObj.teams.length; i++) {
        await axios({
          method: "get",
          url: `${URL_API}team/${projectObj.teams[i]}`,
          headers: getHeader(this.userData),
        }).then((response) => {
          for (var j = 0; j < response.data.members.length; j++) {
            teamObj.push(response.data.members[j]);
          }
        });
      }

      // Remove duplicated items
      teamObj = teamObj.filter(function (value, index, array) {
        return array.indexOf(value) === index;
      });

      this.userOptions = [];
      for (var i = 0; i < teamObj.length; i++) {
        await axios({
          method: "get",
          url: `${URL_API}user/userEmailById/${teamObj[i]}`,
          headers: getHeader(this.userData),
        }).then((response) => {
          this.userOptions.push({
            label: response.data.email,
            value: response.data.id,
          });
        });
      }
    },

    deleteTask() {
      axios({
        method: "delete",
        url: `${URL_API}task/${this.taskData.id}`,
        headers: getHeader(this.userData),
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.deleted"),
              icon: "CheckIcon",
              variant: "success",
              text: `A tarefa ${this.taskData.subject} foi deletada com sucesso`,
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            taskData.value = undefined;
          }
        });
    },

    restrictions(value) {
      return AccessControl(value);
    },

    updateTask() {
      axios({
        method: "put",
        url: `${URL_API}task/${this.taskData.id}`,
        headers: getHeader(this.userData),
        data: this.taskData,
      })
        .then(() => {
          this.$root.$emit("newKanbanTask", null);
        })
        .then(() => {
          let unique1 = this.taskData.members.filter(
            (o) => this.taskOldMembers.indexOf(o) === -1
          );
          let unique2 = this.taskOldMembers.filter(
            (o) => this.taskData.members.indexOf(o) === -1
          );

          const diff = unique1.concat(unique2); // Diferença entre lista antiga e nova.

          let newMembers = diff.filter((x) => !this.taskOldMembers.includes(x)); //Novos membros

          var taskDataEmail = this.taskData;
          taskDataEmail.members = newMembers;

          this.sendEmail("task", taskDataEmail);

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.saved"),
              icon: "CheckIcon",
              variant: "success",
              text: this.$i18n.t("message.toast.updatedB"),
            },
          });
        });
    },

    sendEmail(type, obj) {
      return Email(type, obj);
    },

    openProjectDetails(projectId) {
      this.$router.push({
        name: "apps-projects-details",
        params: { id: projectId },
      });
    },

    gradientColorProgressBar() {
      return `background: linear-gradient(90deg, currentColor ${this.taskData.percentage}%, rgba(0,0,0,0.1) ${this.taskData.percentage}%); animationDelay: -${this.taskData.percentage}s; !important`;
    },
  },

  //   setup(props, { emit }) {
  //    const blanktaskData = {};

  //    const taskData = ref(JSON.parse(JSON.stringify(blanktaskData)));
  //    const resettaskData = () => {
  //      taskData.value = JSON.parse(JSON.stringify(blanktaskData));
  //    };

  //    const onSubmit = () => {
  //      emit("refetch-data");
  //      emit("update:is-add-new-task-sidebar-active", false);
  //    };

  //    const { refFormObserver, getValidationState, resetForm } =
  //      formValidation(resettaskData);

  //    return {
  //      onSubmit,
  //      taskData,

  //     refFormObserver,
  //     getValidationState,
  //     resetForm,
  //    };
  //  },

  setup() {
    const taskData = ref(null);

    axios({
      method: "get",
      url: `${URL_API}task/${router.currentRoute.params.id}`,
      headers: getHeader(store.state.user.userData),
    })
      .then((response) => {
        taskData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          taskData.value = undefined;
        }
      });

    return {
      taskData,

      vSelect,
    };
  },
};
</script>

<style>
.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  outline: none;
  animation: color-range 100s linear forwards paused;
  background: linear-gradient(90deg, currentColor 50%, rgba(0, 0, 0, 0.1) 50%);
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #313a46;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04aa6d;
  cursor: pointer;
}

@keyframes color-range {
  0% {
    color: #f57070;
  }
  50% {
    color: #f5f570;
  }
  100% {
    color: #70f570;
  } 
}
</style>
